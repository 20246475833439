import './App.css';
import React from 'react';
// import Navbar from './Components/navbar.js';
// import Content from './Components/content.js';
import PrivacyPolicy from './Pages/privacypolicy.js';
import TermsOfService from './Pages/termsofservice.js';
import Main from './Pages/main.js';
import Contact from './Pages/contact.js';
import Uninstall from './Pages/uninstall.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/uninstall" element={<Uninstall />} />
      </Routes>
    </Router>
  );
}

export default App;
