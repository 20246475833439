import React, { useState } from 'react';
import './navbar.css';
// import logo from '../logo.png';
import gs_copilot_logo from "../assets/images/gs copilot logo purple.png";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="navbar">
            <div className='navbar_branding'>
                <img src={gs_copilot_logo} alt="App Script Logo" className='app_script_logo' />
                <h1 className="navbar-brand">GS Copilot</h1>
            </div>
            <nav className={`navbar_nav ${isMenuOpen ? 'open' : ''}`}>
                <ul className="navbar_options">
                    <li><a className="nav-item" href='#home'>Home</a></li>
                    <li><a className="nav-item" href='#services'>Services</a></li>
                    <li><a className="nav-item" href='#features'>Features</a></li>
                    <li><a className="nav-item" href='#pricing'>Pricing</a></li>
                    <li><a className="nav-item" href='#contact'>Contact</a></li>
                </ul>
                <div className='navbar_buttons_div'>
                <button className="navbar_signin_button">
  <a className='navbar_dowload_button' rel="noreferrer" target="_blank" href='https://chromewebstore.google.com/detail/google-apps-script-copilo/aakmllddlcknkbcgjabmcgggfciofbgo'>
    Download <i className="fas fa-cloud-download-alt"></i>
  </a>
</button>
                </div>
            </nav>
            <button className="hamburger_menu" onClick={toggleMenu}>
                &#9776;
            </button>
        </div>
    );
}

export default Navbar;