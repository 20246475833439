import React from "react";
import "./content.css";
import inline_suggestion from "../assets/videos/inline suggestion.mp4";
import chat_feature from "../assets/videos/chat feature.mp4";
import spotlight_feature from "../assets/videos/spotlight feature.mp4";
import comment_base_suggestion from "../assets/videos/comment based suggestions.mp4";
import intro_video from "../assets/videos/gs copilot intro.mp4";
import logo_white from "../assets/images/gs copilot logo whit.png";
import { Link } from "react-router-dom";

const Content = () => {
  return (
    <div className="content" id="content">
      <div className="main_page_home" id="home">
        <div className="main_page_home_text">
          <h1 className="main_page_home_titletext">
            Unleash Your Scripting Potential With GS Copilot.
          </h1>
          <a
            className="main_page_home_linkdiv"
            rel="noreferrer"
            target="_blank"
            href="https://chromewebstore.google.com/detail/google-apps-script-copilo/aakmllddlcknkbcgjabmcgggfciofbgo"
          >
            Get started with GS Copilot
          </a>
        </div>
        <div className="main_page_home_imgdiv">
        <video
  className="main_page_home_image" autoPlay muted loop playsInline width="640" height="360"
  src={intro_video}
  alt="GS Copilot logo"
  controlsList="nodownload nofullscreen noremoteplayback nooptions"
></video>
        </div>
      </div>
      <div className="main_page_service" id="services">
        <div className="main_page_service_tag">Services</div>
        <h1 className="main_page_service_text">
          The competitive advantage developers ask for by name.
        </h1>
        <div className="main_page_service_list">
          <div className="main_page_service_list_item_longated">
            <h1 className="main_page_service_list_item_title">
              Proven to increase developer productivity and accelerate the pace
              of software development.
            </h1>
          </div>
          <div
            className="main_page_service_list_item"
            id="main_page_list_item1"
          >
            <div>
              <h1 className="main_page_service_listitem_h1">35%</h1>
              <p className="main_page_service_listitem_ptag">Faster Coding</p>
            </div>
          </div>
          <div
            className="main_page_service_list_item"
            id="main_page_service_list_item_extra"
          >
            <div className="main_page_service_list_item_logodiv">
              <img
                src={logo_white}
                alt="white logo"
                className="main_page_service_list_item_logo"
              />
            </div>
            <div>
              <h1 className="main_page_service_list_item_h1tag">
                Designed by leaders in AI so you can build with confidence.
              </h1>
            </div>
          </div>
          <div className="main_page_service_list_item_longated">
            <h1 className="main_page_service_list_item_title">
              Google Apps Script Copilot provides code suggestions for Google Apps Script,
              making it easier to write scripts.
            </h1>
          </div>
        </div>
        <h1 className="main_page_service_text">Our Standards.</h1>
        <div className="main_page_service_standards">
          <div className="main_page_service_standards_item">
            <h1 className="main_page_service_standards_item_h1tag">
              24/7 Support
            </h1>
            <p className="main_page_service_standards_item_ptag">
              Our team is always available to help you with any questions or
              concerns.
            </p>
          </div>
          <div className="main_page_service_standards_item">
            <h1 className="main_page_service_standards_item_h1tag">
              99.9% Uptime
            </h1>
            <p className="main_page_service_standards_item_ptag">
              Our service is always available, so you can focus on your work.
            </p>
          </div>
          <div className="main_page_service_standards_item">
            <h1 className="main_page_service_standards_item_h1tag">
              Secure
            </h1>
            <p className="main_page_service_standards_item_ptag">
              We take security seriously and ensure your data is always safe.
            </p>
          </div>
        </div>
      </div>
      <div className="main_page_features" id="features">
        <div className="main_page_features_tag">Features</div>
        <div className="main_page_features_h1tag">
          The AI coding assistant elevating developer workflows.
        </div>
       <div className="main_page_feature_1"> 
    <div className="main_page_feature_1_Text">
        <h3 className="main_page_feature_1_Text_h1"> Inline Suggestions for Streamlined Coding!</h3>
        <p className="main_page_feature_1_Text_ptag">The Inline Suggestion feature in GS Copilot intelligently assists developers by suggesting code snippets, syntax corrections, and relevant functions as they type. This real-time guidance helps reduce coding errors and accelerates workflow, ensuring a seamless coding experience tailored to each developer's unique style and project needs. With inline suggestions, you can focus more on creativity and problem-solving while GS Copilot handles routine coding tasks efficiently.</p>
    </div>
    <video className="main_page_feature_1_video" src={inline_suggestion} autoPlay muted loop playsInline width="640" height="360"></video>
        </div>
        <div className="main_page_feature_1" id="main_page_feature_alternative1"> 
        <video className="main_page_feature_1_video" src={chat_feature} autoPlay muted loop playsInline></video>
            <div className="main_page_feature_1_Text">
                <h3 className="main_page_feature_1_Text_h1">Interactive Chat Assistance for Enhanced Problem-Solving</h3>
                <p className="main_page_feature_1_Text_ptag">The Chat feature in GS Copilot offers developers an interactive, conversational interface to ask questions, troubleshoot code, and explore solutions in real time. By leveraging AI-driven insights, the chat provides contextual answers, code examples, and explanations to support both learning and project-specific problem-solving. This feature helps developers tackle complex issues more effectively, streamlining the coding process and enhancing productivity with responsive, on-demand support.</p>
                </div>
        </div>
        <div className="main_page_feature_1"> 
            <div className="main_page_feature_1_Text">
                <h3 className="main_page_feature_1_Text_h1">Search for Quick Access to Code and Documentation.</h3>
                <p className="main_page_feature_1_Text_ptag">The Spotlight feature in GS Copilot empowers developers with a powerful, intuitive search tool to quickly locate code snippets, documentation, and project files without breaking workflow. By simply invoking the Spotlight search, developers can swiftly access relevant information, commands, and functions, reducing the need to switch between tabs or external resources. </p>
                </div>
            <video className="main_page_feature_1_video" src={spotlight_feature} autoPlay muted loop playsInline></video>
        </div>
        <div className="main_page_feature_1" id="main_page_feature_alternative2"> 
        <video className="main_page_feature_1_video" src={comment_base_suggestion} autoPlay muted loop playsInline></video>
            <div className="main_page_feature_1_Text">
                <h3 className="main_page_feature_1_Text_h1">Comment-Based Suggestions for Context-Aware Code Generation.</h3>
                <p className="main_page_feature_1_Text_ptag">The Comment-Based Suggestions feature in GS Copilot allows developers to write natural-language comments that the AI interprets to generate relevant code snippets and solutions. By understanding the intent described in comments, GS Copilot provides context-aware recommendations, saving time on boilerplate coding and helping to align code with the developer’s specific requirements.</p>
                </div>
        </div>
      </div>
      <div className="main_page_payment_section" id="pricing">
        <div className="main_page_payment">
        <div className="main_page_service_tag">Pricing</div>
          <div className="main_page_payment_text">
            <h1 className="main_page_service_text">Start Your Journey With GS Copilot.</h1>
            <p className="main_page_payment_ptag">
            Google Apps Script developers all over the world use GS Copilot to code faster, drive impact, and focus on doing what matters most: building great software.
            </p>
          </div>
          <div className="main_page_payment_div">
            
            <div className="main_page_payment_plan">
              <h1 className="main_page_payment_plan_h1tag">GS Copilot Free:</h1>
              <div className="main_page_payment_benifits_div">
              <p className="main_page_payment_benifits"><i className="fa-solid fa-check" style={{color: "#63E6BE"}}></i> Chat Feature</p>
              <p className="main_page_payment_benifits"><i className="fas fa-check" style={{color: "#63E6BE"}}></i>  Spotlight Feature</p>
              <p className="main_page_payment_benifits"><i className="fas fa-check" style={{color: "#63E6BE"}}></i>  Comment Base Suggestion</p>
              <p className="main_page_payment_benifits"> <i className="fas fa-xmark" style={{color: "#ff5c5c"}}></i>  Inline Suggestion</p>
              </div>
              <p className="main_page_payment_plan_ptag">
                For individuals and small teams.
              </p>
              <a href="https://chromewebstore.google.com/detail/google-apps-script-copilo/aakmllddlcknkbcgjabmcgggfciofbgo"
              target="_blank" rel="noreferrer" className="main_page_payment_plan_button">Get Started <i className="fas fa-arrow-right"></i></a>
            </div>
            <div className="main_page_payment_plan">
              <h1 className="main_page_payment_plan_h1tag">GS Copilot Pro:</h1>
              <div className="main_page_payment_benifits_div">
              <p className="main_page_payment_benifits"><i className="fa-solid fa-check" style={{color: "#63E6BE"}}></i> Chat Feature</p>
              <p className="main_page_payment_benifits"><i className="fas fa-check" style={{color: "#63E6BE"}}></i>  Spotlight Feature</p>
              <p className="main_page_payment_benifits"><i className="fas fa-check" style={{color: "#63E6BE"}}></i>  Comment Base Suggestion</p>
              <p className="main_page_payment_benifits"><i className="fas fa-check" style={{color: "#63E6BE"}}></i>  Inline Suggestion</p>
              </div>
              <p className="main_page_payment_plan_ptag">
                For larger teams and organizations.
              </p>
              <a href="https://bytelogist.lemonsqueezy.com/buy/1fa866af-6641-493c-80c6-71c43e3c790a"
              target="_blank" rel="noreferrer" className="main_page_payment_plan_button">Get Started <i className="fas fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div className="main_page_contactcard_section" id="contact">
        <div className="main_page_contactcard">
          <Link className="main_page_contactcard_button" to="/contact">Contact Us <i className="fas fa-arrow-right"></i></Link>
          <p className="main_page_contactcard_ptag">
            We're here to help you with any questions or concerns you may have.
          </p>
          </div>
      </div>
      <div className="main_page_contact">
      <div className="main_page_footer">
        <div className="main_page_footer_content">
        <p className="main_page_footer_poweredby">
            Powered by <a href="https://www.linkedin.com/company/bytelogist/" className="main_page_footer_company_link">Bytelogist</a>
          </p>
          <div className="main_page_footer_social">
  <a href="https://twitter.com/yourcompany" className="main_page_footer_link">
    <i className="fab fa-twitter"></i>
  </a>
  <a href="https://www.linkedin.com/company/google-apps-script-copilot/" className="main_page_footer_link">
    <i className="fab fa-linkedin-in"></i>
  </a>
</div>
<div className="main_page_footer_links">
            <Link to="./privacypolicy" className="main_page_footer_link">Privacy Policy</Link>
            <Link to="./termsofservice" className="main_page_footer_link">Terms of Service</Link>
          </div>
          <p className="main_page_footer_text">
            &copy; 2023 GS Copilot. All rights reserved.
          </p>
          
        </div>
      </div>
      </div>
    </div>
  );
};

export default Content;