import React from "react";
import "./contact.css";
import gs_copilot_logo from "../assets/images/gs copilot logo purple.png";
// import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'
import { Link } from "react-router-dom";

const Contact = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const options = {
      method: 'POST',
      // headers: {'Content-Type': 'application/json', 'User-Agent': 'insomnia/10.1.1'},
      body: `{"action":"sendEmail","name":"${name}","email":"${email}","body":"${message}","phoneNumber":"${phone}"}`,
    };
    
    fetch('https://script.google.com/macros/s/AKfycbz9ObpGMCcXlTtW3ay87_RXqCXj423WhV1own18uw1lrejfEEKTHvCWX-Y7Nsc63fFT/exec', options)
      .then(response => {
        console.log(response);
        Swal.fire({
          title: "Success!",
          text: "Message sent successfully!",
          icon: "success"
        });
        setName("");
        setEmail("");
        setMessage("");
        setPhone("");
      })
      .then(response => console.log(response))
      .catch(err => {
        console.error(err);
        Swal.fire({
          title: "Error!",
          text: "Message not sent. Please try again later.",
          icon: "error"
        });
      });
  };

  return (
    <div className="contact">
      <div className="left_section">
        <img src={gs_copilot_logo} alt="App Script Logo" className="app_script_logo_branding" />
        <h1 className="h1_tag_branding">GS Copilot</h1>
        <p className="p_tag_branding">Contact us for any query regarding the google apps script copilot, bugs report or suggestion to improve the software you give the best experience possible</p>
        <div className="social_media">
        <a href="https://twitter.com/yourcompany" className="main_page_footer_link">
    <i className="fab fa-twitter"></i>
  </a>
  <a href="https://www.linkedin.com/company/google-apps-script-copilot/" className="main_page_footer_link">
    <i className="fab fa-linkedin-in"></i>
  </a>
      </div>
      </div>
      <div className="right_section">
        <form className="contact-form">
          <h1 className="h1_tag">Contact Us</h1>
          <p className="p_tag">Feel free to drop us a message</p>
          <label className="label_text">First Name:</label>
          <input className="input_field"
           type="text"
           value={name}
           onChange={(e) => setName(e.target.value)}
           placeholder="Enter your name" required />
          <label className="label_text">Email:</label>
          <input className="input_field" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email " required />
          <label className="label_text">Message:</label>
          <textarea className="text_field" value={message} onChange={(e) => setMessage(e.target.value)} required  placeholder="Leave your message here" rows="5" maxLength={300} style={{ resize: "none" }}></textarea>
          <label className="label_text">Phone Number:</label>
          <input className="input_field" value={phone} onChange={(e) => setPhone(e.target.value)} type="text"  placeholder="Enter your phone number" />
          <div className="button_div">
            <Link className="cancel_button" to="/" type="button">Cancel</Link>
            <button className="submit_button" type="submit" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;